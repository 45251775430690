/* src/CustomModal.css */
.custom-modal-wrapper {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.9);
    z-index: 1000;
}

.custom-modal-wrapper.show {
    display: block;
}

.custom-view-window {
    position: relative;
    padding-bottom: 120px;
    height: 100vh;
    overflow: scroll;
    -webkit-overflow-scrolling: auto;
}

.custom-modal-content-wrapper {
    position: absolute;
    max-width: 900px;
    width: 50%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    background: blue;
    color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
}

.custom-modal-body {
    background: #fff;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 100px;
    background: blue;
    color: white;
}

.custom-modal-close {
    position: absolute;
    right: -10px;
    top: 5px;
    z-index: 20;
    transform: translateX(100%);
    cursor: pointer;
}