/* src/HamburgerMenu.css */
.sp-nav_nav-list {
    position: fixed;
    width: 100%;
    top: -1000px;
    transition: all 0.2s;
    height: 100%;
    z-index: 116;
    right: -4px;
}

.sp-nav_nav-list.nav-opened {
    top: 0px;
}

.sp-nav_nav-list_box {
    list-style: none;
    text-align: center;
    background-color: rgb(227, 227, 227);
    padding: 60px 0 30px;
    width: 100%;
    margin: 0 auto;
    flex-direction: column;
}

.sp-nav_nav-list_box_list {
    margin: 0 auto;
    margin-bottom: 40px;
    width: 70%;
    height: 64px;
    cursor: pointer;
}

.sp-nav_nav-list_box_list {
    .menu-link {
        color: black;
        font-size: 13px;
    }
}

.sp-header-nav_box_ham {
    position: fixed;
    transition: all 0.3s;
    cursor: pointer;
    display: block;
    right: 0px;
    top: 0px;
    width: 64px;
    height: 64px;
    z-index: 200;
    background-color: rgb(168, 168, 168);
    border-radius: 0 0 0px 13px;
}

.sp-header-nav_box_ham.clicked {
    background-color: inherit;
}

.sp-header-nav_box_ham_line {
    display: block;
    position: absolute;
    transition: all 0.6s ease;
    background-color: white;
    width: 22px;
    height: 2px;
    border-radius: 3px;
}

.line-1 {
    top: 20px;
    right: 20px;
}

.line-2 {
    top: 30px;
    right: 20px;
}

.line-3 {
    top: 40px;
    right: 20px;
}

.clicked .line-1 {
    transform: rotate(315deg);
    top: 30px;
}

.clicked .line-2 {
    opacity: 0;
}

.clicked .line-3 {
    transform: rotate(405deg);
    top: 30px;
}