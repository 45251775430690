/* src/Tabs.css */
.tab-wrap {
    width: 100%;
}

.tab-btns {
    display: flex;
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.tab-btn {
    padding: 10px 20px;
    cursor: pointer;
    border: 1px solid #ccc;
    border-bottom: none;
    background-color: #f7f7f7;
}

.tab-btn.show {
    background-color: white;
    border-top: 2px solid blue;
    font-weight: bold;
}

.tab-content {
    display: none;
    border: 1px solid #ccc;
    padding: 10px;
    background-color: white;
}

.tab-content.show {
    display: block;
}