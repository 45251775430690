/* フェードインおよびスクロールアップ用のCSS */
.scroll-fade {
    opacity: 0;
    transition: all 2s;
    /* 処理にかかる時間 */
}

.scroll-up {
    opacity: 0;
    transform: translateY(100px);
    /* スクロールアップする距離 */
    transition: all 2s;
    /* 処理にかかる時間 */
}

.scroll-up.done,
.scroll-fade.done {
    opacity: 1;
    transform: translate(0, 0);
}