/* src/Accordion.css */
.accordion-item {
    border: 1px solid #ccc;
    margin: 10px 0;
    border-radius: 5px;
}

.accordion-title {
    background-color: #f7f7f7;
    padding: 10px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.accordion-content {
    overflow: hidden;
    transition: max-height 0.3s ease-out;
    background-color: #fff;
}