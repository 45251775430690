/* src/Modal.css */
.modal-wrapper {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(190, 190, 190, 0.9);
    z-index: 1000;
}

.modal-wrapper.show {
    display: block;
    justify-content: center;
    align-items: center;
}

.modal-wrapper .view-window {
    position: relative;
    padding-bottom: 120px;
    height: 100vh;
    overflow: scroll;
    -webkit-overflow-scrolling: auto;
}

.modal-wrapper .modal-content-wrapper {
    position: absolute;
    max-width: 900px;
    width: 50%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    background: white;
    /* 追加 */
    padding: 20px;
    /* 追加 */
    border-radius: 10px;
    /* 追加 */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
    /* 追加 */
}

.modal-wrapper .modal-content-wrapper .modal-body {
    background: #fff;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 100px;
    /* 追加 */
}

.modal-wrapper .btn-close {
    position: absolute;
    right: -10px;
    top: 5px;
    z-index: 20;
    transform: translateX(100%);
    cursor: pointer;
}

.modal-wrapper .btn-prev,
.modal-wrapper .btn-next {
    position: absolute;
    top: 50%;
    z-index: 100;
    cursor: pointer;
}

.modal-wrapper .btn-prev {
    left: 20px;
}

.modal-wrapper .btn-next {
    right: 20px;
}

.modal-wrapper .txt-close {
    font-size: 16px;
    letter-spacing: 1.76px;
    color: #000000;
    text-align: center;
    cursor: pointer;
    position: relative;
    top: 30px;
}

.modal-wrapper_box {
    position: relative;
}