.custom-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    cursor: pointer;
    background: none;
    border: none;
}

.custom-arrow.prev {
    left: 80px;
    width: 50px;
}

.custom-arrow.next {
    right: 80px;
    width: 50px;
}

.custom-arrow button {
    background-color: #000;
    color: #fff;
    padding: 10px;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    outline: none;
    font-size: 16px;
}

.custom-arrow button:hover {
    background-color: #444;
}